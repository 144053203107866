<template>
  <div class="d-flex justify-content-center body py-5 px-2" style="height: 100vh">
    <h6 class="carregando text-center text-muted" v-if="!fatura && carregando" style="display: flex; align-items: center">
      <i class="fa fa-spin fa-spinner"></i>&nbsp;CARREGANDO...
    </h6>
    <section class="fatura col-md-10" v-if="fatura && !carregando">
      <div class="row">
        <div class="col-md-4 text-center text-md-left mb-4 mb-md-0">
          <a href="/"><img :src="logo" alt="logo" :title="site_name" width="200" /></a>
        </div>
        <div class="col-md-8 text-md-right">
          <h3>
            FATURA <small class="text-muted">#{{ fatura.codigo }}</small>
          </h3>
          <p>
            <span
              class="badge px-2 py-1"
              v-bind:class="{
                'badge-warning': ['pendente', 'aguardando pagamento'].includes(fatura.status),
                'badge-danger': ['cancelada', 'vencida'].includes(fatura.status),
                'badge-success': ['pago', 'pago maior', 'pago menor'].includes(fatura.status),
              }"
              >{{ fatura.status | uppercase }}</span
            >
          </p>
        </div>
      </div>

      <div class="row row-info">
        <div class="cliente col-md-6 d-flex flex-column">
          <div class="d-flex flex-column">
            <label>CLIENTE</label>
            <p>{{ fatura.cliente.nomeCliente | uppercase }}</p>
          </div>

          <div class="d-flex flex-column">
            <label>CPF/CNPJ</label>
            <p>{{ fatura.cliente.cpfCpnj | uppercase }}</p>
          </div>

          <div class="d-flex flex-column">
            <label>ENDEREÇO</label>
            <p>
              {{ fatura.cliente.logradouroCliente | uppercase }}, {{ fatura.cliente.numeroCliente | uppercase }},
              {{ fatura.cliente.bairroCliente | uppercase }}, {{ fatura.cliente.complementoCliente | uppercase }},
              {{ fatura.cliente.cidadeCliente | uppercase }}, {{ fatura.cliente.estadoCliente | uppercase }},
              <br />
              CEP:{{ fatura.cliente.cepCliente | uppercase }}
            </p>
          </div>

          <div class="d-flex flex-column">
            <label>LEILÃO</label>
            <p>{{ fatura.nomeLeilao | uppercase }}</p>
          </div>
        </div>

        <div class="cedente col-md-6 d-none d-md-block flex-column">
          <div class="d-flex flex-column">
            <label class="txt-end">CEDENTE</label>
            <p class="txt-end">
              {{ fatura.empresa.nomeEmpresa | uppercase }}
            </p>
          </div>
          <div class="d-flex flex-column">
            <label class="txt-end">CPF/CNPJ</label>
            <p class="txt-end">
              {{ fatura.empresa.cpfCnpjEmpresa | uppercase }}
            </p>
          </div>
          <div class="d-flex flex-column">
            <label class="txt-end">ENDEREÇO</label>
            <p class="txt-end">
              {{ fatura.empresa.logradouroEmpresa | uppercase }}, {{ fatura.empresa.numeroEmpresa | uppercase }},
              {{ fatura.empresa.bairroEmpresa | uppercase }}, {{ fatura.empresa.complementoEmpresa | uppercase }},
              {{ fatura.empresa.cidadeEmpresa | uppercase }}, {{ fatura.empresa.estadoEmpresa | uppercase }},

              <br />
              CEP:{{ fatura.empresa.cepEmpresa | uppercase }}
            </p>
          </div>
        </div>
      </div>

      <div class="row py-2">
        <div class="col-6 align-self-center">
          <h5>DETALHES DA FATURA</h5>
        </div>
        <div class="col-6 d-flex text-right justify-content-center flex-column">
          <label class="text-muted pb-2">VENCIMENTO</label>
          <h5>{{ dataVencimento }}</h5>
        </div>
      </div>

      <div class="row">
        <table class="table table-sm">
          <thead>
            <tr>
              <th class="text-muted"><small>DESCRIÇÃO</small></th>
              <th class="text-right text-muted" width="200">
                <small>VALOR</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in itensFatura" :key="index">
              <td>
                <small> {{ item.descricao }}</small>
              </td>
              <td class="text-right text-nowrap">
                {{
                  item.valor
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th class="text-right">Subtotal</th>
              <th class="text-right text-nowrap">
                {{
                  fatura.valorTotal
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>

      <div v-if="fatura.status == 'pendente'">
        <div class="row row-title-pagamento">
          <div class="col-12 py-5">
            <h5>PAGAMENTO DA FATURA</h5>
            <label>Efetue o pagamento pela internet com segurança ou em qualquer agencia bancária. Escolha uma das opções de pagamento abaixo</label>
          </div>
        </div>

        <div class="row">
          <ul class="nav nav-tabs col-12">
            <li class="nav-item col-6" v-if="fatura.formasPagamento.includes('Cartão de Crédito')">
              <a
                class="nav-link d-flex justify-content-center"
                v-bind:class="{
                  active: cartaoBoolean,
                  inactive: boletoBoolean || pixBoolean || guiaBoolean,
                }"
                @click="changeOpcaoPagamento('cartao')"
                aria-current="page"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-credit-card-fill" viewBox="0 0 16 16">
                  <path
                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
                  />
                </svg>
                &nbsp;<b>Crédito</b>
              </a>
            </li>
            <li class="nav-item col-6" v-if="fatura.formasPagamento.includes('Boleto')">
              <a
                class="nav-link d-flex justify-content-center"
                v-bind:class="{
                  active: boletoBoolean,
                  inactive: cartaoBoolean || pixBoolean || guiaBoolean,
                }"
                @click="changeOpcaoPagamento('boleto')"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
                  <path
                    d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"
                  />
                </svg>
                &nbsp;<b>Boleto</b>
              </a>
            </li>
            <li class="nav-item col-6" v-if="fatura.formasPagamento.includes('Pix Estático')">
              <a
                class="nav-link d-flex justify-content-center"
                v-bind:class="{
                  active: pixBoolean,
                  inactive: cartaoBoolean || boletoBoolean || guiaBoolean,
                }"
                @click="changeOpcaoPagamento('pix')"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
                  <path
                    d="M 15 1.0996094 C 13.975 1.0996094 12.949922 1.4895313 12.169922 2.2695312 L 7.1894531 7.25 L 7.3398438 7.25 C 8.6098437 7.25 9.7992188 7.740625 10.699219 8.640625 L 14.189453 12.130859 C 14.639453 12.570859 15.360547 12.570859 15.810547 12.130859 L 19.300781 8.640625 C 20.200781 7.740625 21.390156 7.25 22.660156 7.25 L 22.810547 7.25 L 17.830078 2.2695312 C 17.050078 1.4895313 16.025 1.0996094 15 1.0996094 z M 5.6894531 8.75 L 2.2695312 12.169922 C 0.70953125 13.729922 0.70953125 16.270078 2.2695312 17.830078 L 5.6894531 21.25 L 7.3398438 21.25 C 8.2098438 21.25 9.030625 20.910781 9.640625 20.300781 L 13.130859 16.810547 C 14.160859 15.780547 15.839141 15.780547 16.869141 16.810547 L 20.359375 20.300781 C 20.969375 20.910781 21.790156 21.25 22.660156 21.25 L 24.310547 21.25 L 27.730469 17.830078 C 29.290469 16.270078 29.290469 13.729922 27.730469 12.169922 L 24.310547 8.75 L 22.660156 8.75 C 21.790156 8.75 20.969375 9.0892188 20.359375 9.6992188 L 16.869141 13.189453 C 16.359141 13.699453 15.68 13.960938 15 13.960938 C 14.32 13.960938 13.640859 13.699453 13.130859 13.189453 L 9.640625 9.6992188 C 9.030625 9.0892187 8.2098437 8.75 7.3398438 8.75 L 5.6894531 8.75 z M 15 17.539062 C 14.7075 17.539062 14.414453 17.649141 14.189453 17.869141 L 10.699219 21.359375 C 9.7992188 22.259375 8.6098437 22.75 7.3398438 22.75 L 7.1894531 22.75 L 12.169922 27.730469 C 13.729922 29.290469 16.270078 29.290469 17.830078 27.730469 L 22.810547 22.75 L 22.660156 22.75 C 21.390156 22.75 20.200781 22.259375 19.300781 21.359375 L 15.810547 17.869141 C 15.585547 17.649141 15.2925 17.539062 15 17.539062 z"
                  />
                </svg>
                &nbsp;<b>PIX</b>
              </a>
            </li>
            <li class="nav-item col-6" v-if="fatura.formasPagamento.includes('Guia de Pagamento')">
              <a
                class="nav-link d-flex justify-content-center"
                v-bind:class="{
                  active: guiaBoolean,
                  inactive: cartaoBoolean || boletoBoolean || pixBoolean,
                }"
                @click="changeOpcaoPagamento('guia')"
              >
                <i class="fa fa-file-pdf"></i>
                &nbsp;
                <b v-if="!isMobile">GUIA DE PAGAMENTO</b>
                <b v-if="isMobile">GUIA</b>
              </a>
            </li>
          </ul>

          <section class="col-12 pt-5" v-show="cartaoBoolean">
            <form id="form-checkout">
              <div class="row">
                <div class="form-group mb-30 col-md-6 col-12">
                  <label for="form-checkout__cardNumber">Número do Cartão </label>
                  <masked-input
                    type="text"
                    class="form-control bp-sop-cardnumber"
                    :mask="[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
                    :guide="true"
                    placeholder="0000.0000.0000.0000"
                    placeholderChar="_"
                    v-model="cartao.numero"
                  >
                  </masked-input>
                </div>
                <div class="form-group mb-30 col-md-6 col-12">
                  <label for="bandeira">Bandeira do Cartão </label>
                  <select id="bandeira" class="form-control" v-model="cartao.bandeira">
                    <option value="Visa">Visa</option>
                    <option value="Master">Mastercard</option>
                    <option value="Amex">American Express</option>
                    <option value="Elo">Elo</option>
                    <option value="Diners">Diners</option>
                    <option value="Discover">Discover</option>
                  </select>
                </div>
                <div class="form-group mb-30 col-12">
                  <label for="form-checkout__cardholderName">Titular do Cartão</label>
                  <input type="text" id="form-checkout__cardholderName" class="form-control bp-sop-cardholdername" />
                </div>
              </div>
              <div class="row">
                <div class="form-group mb-30 col-md-2 col-12">
                  <label for="form-checkout__expirationDate">Validade</label>
                  <masked-input
                    type="text"
                    class="form-control bp-sop-cardexpirationdate"
                    :mask="[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
                    :guide="true"
                    placeholderChar="_"
                    placeholder="MM/AAAA"
                    v-model="cartao.validade"
                  >
                  </masked-input>
                </div>
                <div class="form-group mb-30 col-md-2 col-12">
                  <label for="form-checkout__expirationDate">Código de Segurança</label>
                  <masked-input
                    type="number"
                    class="form-control bp-sop-cardcvv"
                    :mask="[/\d/, /\d/, /\d/]"
                    :guide="false"
                    placeholder="123"
                    maxlength="3"
                    v-model="cartao.cvv"
                  >
                  </masked-input>
                </div>

                <!-- <div class="row">
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__identificationType">Documento</label>
                  <select id="form-checkout__identificationType" class="form-control"></select>
                </div>
                <div class="form-group mb-30 col-6">
                  <label for="form-checkout__identificationNumber">Número do documento</label>
                  <input type="text" id="form-checkout__identificationNumber" class="form-control" />
                </div>
              </div> -->

                <!-- <div class="form-group mb-30 col-6">
                  <label for="form-checkout__cardholderEmail">E-mail</label>
                  <input type="email" id="form-checkout__cardholderEmail" class="form-control" />
                </div> -->
                <div class="form-group mb-30 col-md-8 col-12">
                  <label for="parcelas">Parcelas</label>
                  <select id="parcelas" @change="mudarParcela()" v-model="cartao.parcela" placeholder="Parcela" class="form-control">
                    <option v-for="(item, index) in fatura.opcoesParcelamento" :key="index" :value="item.parcela">
                      {{ item.parcela === 1 ? 'Avista - ' : item.parcela + 'x de ' }}
                      {{
                        item.valorMensal
                          | currency('R$', 2, {
                            spaceBetweenAmountAndSymbol: true,
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="alert alert-danger" role="alert" v-if="!enviando && cieloErrors.length > 0">
                <h6 class="alert-heading">Verifique os erros!</h6>
                <hr />
                <ol>
                  <li class="mb-0" v-for="erro in cieloErrors" :key="erro.Field">
                    {{ getFieldName(erro.Field) + ' ' + erro.Message }}
                  </li>
                </ol>
              </div>

              <div
                class="alert alert-danger"
                role="alert"
                v-if="
                  !enviando &&
                  !pagamentoResponse &&
                  fatura.pagamento &&
                  (fatura.pagamento.status == 'rejeitado' || fatura.pagamento.status == 'cancelado')
                "
              >
                A tentativa de pagamento anterior foi rejeitada ou cancelada pelo cartão, por favor tente novamente realizar o pagamento ou use outro
                cartão.
              </div>

              <div class="alert" :class="pagamentoStatus" role="alert" v-if="!enviando && pagamentoResponse">
                {{ pagamentoResponse.mensagem }}
              </div>

              <select id="form-checkout__installments" hidden></select>
              <div class="row">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th class="text-right text-muted">Taxa cartão</th>
                      <th class="text-right text-muted" width="200">
                        {{
                          this.valorTaxaCartao
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td class="text-right"><b>Total</b></td>
                      <td class="text-right text-nowrap">
                        <b>
                          {{
                            this.valorTotalCartao
                              | currency('R$', 2, {
                                spaceBetweenAmountAndSymbol: true,
                                decimalSeparator: ',',
                                thousandsSeparator: '.',
                              })
                          }}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button class="btn-pagar" type="button" :disabled="enviando" @click="realizarPagamento">
                    <span v-if="!enviando">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
                        <path
                          d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                        />
                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                      </svg>
                      Pagar com cartão
                    </span>
                    <span v-else> <i class="fa fa-spinner fa-spin"></i>&nbsp; Enviando... </span>
                  </button>
                </div>
              </div>
            </form>
          </section>
          <div v-show="boletoBoolean" class="w-100 text-center">
            <div class="boleto" v-if="fatura.boleto">
              <iframe :src="fatura.boleto.url" frameborder="0"></iframe>
            </div>
            <div class="row" v-if="fatura.boleto">
              <div class="col-12">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th class="text-right text-muted">Taxa Boleto</th>
                      <th class="text-right text-muted" width="200">
                        {{
                          fatura.boleto.taxa
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td class="text-right"><b>Total</b></td>
                      <td class="text-right text-nowrap">
                        <b>
                          {{
                            (fatura.valorTotal + fatura.boleto.taxa)
                              | currency('R$', 2, {
                                spaceBetweenAmountAndSymbol: true,
                                decimalSeparator: ',',
                                thousandsSeparator: '.',
                              })
                          }}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button @click="download" class="btn-pagar"><i class="fa fa-download"></i>&nbsp;Baixar Boleto</button>
          </div>
          <div v-show="pixBoolean" class="w-100 text-center" v-if="fatura.pix">
            <div class="row mt-4 justify-content-center">
              <div class="col-md-6 align-items-center">
                <div class="p-3 border" style="width: 250px; margin: 0 auto">
                  <qrcode-vue :value="fatura.pix.qrString" :size="200"></qrcode-vue>
                </div>
                <button class="btn btn-outline-secondary mt-2" style="width: 250px" @click="clipboard()">Pix Copia e Cola</button>
              </div>
              <div class="col-md-6 text-left mt-3">
                <p>
                  Procure em seu aplicativo de banco ou conta digital a funcionalidade PIX e escaneie o QRCode ou copie o código usando o botão "Pix
                  Copia e Cola" para efetuar o pagamento.
                </p>
                <p>
                  <i class="fa fa-exclamation-triangle text-warning"></i> ATENÇÃO: Após o pagamento deve ser enviado comprovante de pagamento para o
                  email: <b>{{ fatura.pix.email }}</b>
                </p>
                <h5>Dados Bancários</h5>
                <p>
                  <b>Banco:</b> {{ fatura.pix.banco }}<br />
                  <b>Agência:</b> {{ fatura.pix.agencia }}<br />
                  <b>Conta:</b> {{ fatura.pix.conta }}<br />
                  <b>Chave PIX:</b> {{ fatura.pix.tipo }}: {{ fatura.pix.chave }}<br />
                  <b>Nome:</b> {{ fatura.pix.nome }}
                </p>
              </div>
            </div>

            <div class="row mt-3" v-if="fatura.pix" style="margin-bottom: 100px">
              <div class="col-12">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th class="text-right text-muted">Taxa do Pix</th>
                      <th class="text-right text-muted" width="200">
                        {{
                          fatura.pix.taxa
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td class="text-right"><b>Total à Pagar</b></td>
                      <td class="text-right text-nowrap">
                        <b>
                          {{
                            (fatura.valorTotal + fatura.pix.taxa)
                              | currency('R$', 2, {
                                spaceBetweenAmountAndSymbol: true,
                                decimalSeparator: ',',
                                thousandsSeparator: '.',
                              })
                          }}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-show="guiaBoolean" class="w-100 mb-5">
            <div class="row mt-5">
              <div class="col-12">
                <table class="table table-sm table-responsive w-100">
                  <thead>
                    <tr>
                      <th class="text-muted text-left w-100">Guia de Pagamento</th>
                      <th class="text-muted text-left" v-if="!isMobile">Tamanho</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in fatura.guiasPagamento" :key="index">
                      <td class="text-left">
                        <a v-bind:href="item.url" target="_blank">{{ item.nome }}</a>
                      </td>
                      <td class="text-left" v-if="!isMobile">{{ formatBytes(item.tamanho) }}</td>
                      <td>
                        <a v-bind:href="item.url" title="Baixar Guia de Pagamento" target="_blank"><i class="fa fa-download"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="fatura.pagamento && fatura.pagamento.status == 'pendente'">
        <div class="row py-2">
          <div class="col-12 align-self-center">
            <h5>DETALHES DO PAGAMENTO</h5>
          </div>
        </div>
        <div class="alert alert-warning" role="alert">O pagamento está sendo processado pela operadora do seu cartão, aguarde.</div>
      </div>
      <div v-else-if="fatura.pagamento && fatura.pagamento.status == 'aprovado'">
        <div class="row py-2">
          <div class="col-12 align-self-center">
            <h5>DETALHES DO PAGAMENTO</h5>
          </div>
        </div>
        <div class="row">
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th class="text-muted"><small>DESCRIÇÃO</small></th>
                <th class="text-right text-muted" width="200"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <small> Data do Pagamento</small>
                </td>
                <td class="text-right text-nowrap">
                  {{ fatura.pagamento.dataPagamento | formatDataHora }}
                </td>
              </tr>

              <tr>
                <td>
                  <small> Forma de Pagamento</small>
                </td>
                <td class="text-right text-nowrap">
                  {{ fatura.pagamento.formaPagamento }}
                </td>
              </tr>

              <tr v-if="fatura.pagamento.formaPagamento == 'Cartão de Crédito'">
                <td>
                  <small> Código de Autorização</small>
                </td>
                <td class="text-right text-nowrap">
                  {{ fatura.pagamento.codigoAutorizacao }}
                </td>
              </tr>

              <tr>
                <td>
                  <small>Taxa</small>
                </td>
                <td class="text-right text-nowrap">
                  {{
                    fatura.pagamento.taxa
                      | currency('R$', 2, {
                        spaceBetweenAmountAndSymbol: true,
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th class="text-right">Total Pago</th>
                <th class="text-right text-nowrap">
                  {{
                    fatura.pagamento.valorPago
                      | currency('R$', 2, {
                        spaceBetweenAmountAndSymbol: true,
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import http from '@/service/http/http';
import { DOWNLOAD_BOLETO_URL } from '@/config/config';
import { mapActions } from 'vuex';
import moment from 'moment';
import { saveAs } from 'file-saver';
// import mpErros from '@/data/mercadoPagoErros.json';
import QrcodeVue from 'qrcode.vue';
moment.locale('pt-br');
import MaskedInput from 'vue-text-mask';
export default {
  name: 'faturas',
  components: {
    QrcodeVue,
    MaskedInput,
  },
  data() {
    return {
      fatura: '',
      faturaToken: '',
      itensFatura: '',
      dataVencimento: '',
      valorTotal: 0,
      valores: '',
      valorTaxaCartao: 0,
      valorTotalCartao: 0,
      cartaoBoolean: false,
      boletoBoolean: false,
      pixBoolean: false,
      guiaBoolean: true,
      cartao: {
        bandeira: '',
        numero: '',
        validade: '',
        cvv: null,
        parcela: 1,
        paymentToken: '',
      },
      // mpErros: [],
      pagamentoResponse: null,
      enviando: false,
      logo: process.env.VUE_APP_LOGO_PATH,
      site_name: process.env.VUE_APP_SITE_NAME,
      windowWidth: window.innerWidth,
      silentToken: null,
      cieloErrors: [],
      carregando: true,
    };
  },
  computed: {
    // mpErroArray() {
    //   return [...new Set(this.mpErros)];
    // },
    pagamentoStatus() {
      switch (this.pagamentoResponse?.status) {
        case 'Authorized':
        case 'PaymentConfirmed':
          return 'alert-success';
        case 'Pending':
          return 'alert-warning';
        case 'Denied':
        case 'Voided':
        case 'Aborted':
          return 'alert-danger';
        default:
          return 'alert-warning';
      }
    },
    isMobile() {
      return this.windowWidth < 576;
    },
  },
  methods: {
    ...mapActions([]),
    async getTokenSilentPost() {
      const resp = await http({
        method: 'GET',
        url: `fatura/SilentToken`,
      });
      this.silentToken = resp.data.data;
    },
    getFieldName(field) {
      switch (field) {
        case 'bp-sop-cardholdername':
          return 'Títular do Cartão';
        case 'bp-sop-cardnumber':
          return 'Número do Cartão';
        case 'bp-sop-cardexpirationdate':
          return 'Validade';
        case 'bp-sop-cardcvv':
          return 'Codigo de Segurança';
      }
    },
    async sendCardData() {
      this.cieloErrors = [];
      this.pagamentoResponse = null;
      //Valida data expiração do token
      if (moment().isAfter(moment(this.silentToken.expiresIn))) {
        await this.getTokenSilentPost();
        this.sendCardData();
        return;
      }

      const options = {
        accessToken: this.silentToken.accessToken,
        onSuccess: (e) => {
          this.cartao.paymentToken = e.PaymentToken;
        },
        onError: (e) => {
          console.log('Error:', e);
          this.pagamentoResponse = {
            status: 'Denied',
            mensagem: e.Text || 'Houve um erro ao realizar o pagamento, tente novamente',
          };
        },
        onInvalid: (e) => {
          console.log('Invalido:', e);
          this.cieloErrors = e;
        },
        environment: 'production', //production
        language: 'PT',
      };

      bpSop_silentOrderPost(options);
      await this.getTokenSilentPost();
    },
    async realizarPagamento() {
      try {
        if (!this.cartao.parcela) {
          this.pagamentoResponse = {
            status: 'Denied',
            mensagem: 'Informe a parcela desejada',
          };
          return false;
        }

        if (!this.cartao.bandeira || this.cartao.bandeira == '') {
          this.pagamentoResponse = {
            status: 'Denied',
            mensagem: 'Informe a bandeira do cartão',
          };
          return false;
        }

        this.pagamentoResponse = null;
        await this.sendCardData();

        if (this.cartao.paymentToken) {
          this.enviando = true;
          const resp = await http({
            method: 'POST',
            url: `fatura/${this.fatura.faturaId}/Pagamento`,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              paymentToken: this.cartao.paymentToken,
              valor: Number(this.valorTotalCartao),
              parcela: Number(this.cartao.parcela),
              descricao: `EBLOnline`,
              bandeira: this.cartao.bandeira,
              cliente: {
                email: this.fatura.cliente.emailCliente,
                nome: this.fatura.cliente.nomeCliente,
              },
            }),
          });
          this.enviando = false;

          this.inicializar();
          //this.pagamentoResponse = resp.data.data;
        }
      } catch (error) {
        this.enviando = false;
        this.pagamentoResponse = {
          status: 'Denied',
          mensagem: 'Houve um erro inesperado, por favor tente novamente.',
        };
      }
    },

    download() {
      http({
        method: 'GET',
        url: `${DOWNLOAD_BOLETO_URL}fatura/${this.fatura.faturaId}/DownloadBoleto`,
        responseType: 'blob',
      }).then((resp) => {
        saveAs(resp.data, 'boleto.pdf');
      });
    },

    getFatura() {
      this.carregando = true;
      return new Promise((resolve, reject) => {
        http({
          url: `fatura/${this.faturaToken}`,
          method: 'get',
        }).then((resp) => {
          this.fatura = resp.data.data;
          this.itensFatura = this.fatura.itensFatura;
          this.dataVencimento = moment(this.fatura.dataVencimento).format('DD/MM/YYYY');
          this.carregando = false;
          resolve();
        });
      });
    },
    changeOpcaoPagamento(option) {
      if (option == 'boleto') {
        this.boletoBoolean = true;
        this.cartaoBoolean = false;
        this.pixBoolean = false;
        this.guiaBoolean = false;
      } else if (option == 'cartao') {
        this.boletoBoolean = false;
        this.cartaoBoolean = true;
        this.pixBoolean = false;
        this.guiaBoolean = false;
      } else if (option == 'pix') {
        this.boletoBoolean = false;
        this.cartaoBoolean = false;
        this.pixBoolean = true;
        this.guiaBoolean = false;
      } else if (option == 'guia') {
        this.boletoBoolean = false;
        this.cartaoBoolean = false;
        this.pixBoolean = false;
        this.guiaBoolean = true;
      }
    },
    mudarParcela() {
      const opcao = this.fatura.opcoesParcelamento.find((x) => x.parcela === this.cartao.parcela);
      this.valorTotalCartao = opcao.parcela * opcao.valorMensal.toFixed(2);
      this.valorTaxaCartao = this.valorTotalCartao - this.fatura.valorTotal.toFixed(2);
    },
    async inicializar() {
      this.faturaToken = this.$route.params.faturaToken;

      await this.getFatura();
      this.mudarParcela();
      // await this.mercadoPagoForm();

      if (
        this.fatura.formasPagamento.includes('Cartão de Crédito') ||
        (this.fatura.formasPagamento.includes('Cartão de Crédito') && this.fatura.formasPagamento.includes('Boleto'))
      ) {
        this.changeOpcaoPagamento('cartao');
      } else if (!this.fatura.formasPagamento.includes('Cartão de Crédito') && this.fatura.formasPagamento.includes('Boleto')) {
        this.changeOpcaoPagamento('boleto');
      } else if (this.fatura.formasPagamento.includes('Pix Estático')) {
        this.changeOpcaoPagamento('pix');
      } else if (this.fatura.formasPagamento.includes('Guia')) {
        this.changeOpcaoPagamento('guia');
      }
    },
    clipboard() {
      navigator.clipboard.writeText(this.fatura.pix.qrString);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) {
        return '0 Bytes';
      }

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
  },
  mounted() {
    this.inicializar();
    this.getTokenSilentPost();
  },
  filters: {
    formatDataHora(value) {
      return moment.utc(value).local().format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style scoped>
#form-checkout__issuer {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
  height: 18px;
  font-size: 14px;
  background-color: #0783ae;
  color: #fff;
  font-weight: bold;
  max-width: 150px;
}

#nrCard {
  display: flex;
  justify-content: space-between;
}

.none-padding-margin {
  margin: 0;
  padding: 0;
}

.row-info {
  border-bottom: 1px dashed black;
  padding-bottom: 20px;
}

.row-title-pagamento {
  border-top: 1px solid black;
}

.body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.nav-tabs {
  padding: 0%;
}

.nav-tabs li {
  padding: 0%;
}

.txt-end {
  text-align: right;
}
.btn-pagar {
  background-color: green;
  border-radius: 5px;
  color: white;
  margin-bottom: 60px;
}

label {
  color: #6c757d !important;
  margin-bottom: 0;
}

p {
  color: black;
  margin-top: 0;
  /* padding-bottom: 1rem; */
}

h5 {
  font-weight: 400;
  font-size: 1rem;
}

svg {
  width: 0.8rem;
  height: 1.3rem;
  margin-right: 1px;
}

table {
  height: 100%;
}

.inactive {
  border: none;
  border-bottom: 1px solid #d7dade !important;
}

.nav-link {
  cursor: pointer;
}

::placeholder {
  font-size: 14px;
}

@media (min-width: 360px) {
  .fatura {
    width: 100%;
  }

  .btn-pagar {
    width: 90%;
  }
}

@media (min-width: 360px) {
  @media (min-height: 640px) {
    .row-detalhes {
      height: 44vh;
    }

    .cartao {
      height: 92vh;
    }
    .boleto {
      width: 360px !important;
    }
  }

  @media (min-width: 800px) {
    .row-detalhes {
      height: 19vh;
    }

    .cedente {
      visibility: visible;
    }

    .btn-pagar {
      width: 100%;
    }

    .boleto {
      width: 660px !important;
    }
  }

  @media (min-width: 1280px) {
    .row-detalhes {
      height: 26vh;
    }
    .boleto {
      width: 660px !important;
    }
  }
}

.boleto {
  width: 100%;
  height: 760px;
  margin: 40px auto;
}

.boleto iframe {
  width: 100%;
  height: 760px;
}
</style>
